import { quickDropAdminApi } from 'config/api';
import i18n from 'config/translation';
import {
  CommentPostRequest,
  LogInfoQueryParams,
  REGEX_OPTIONS,
  REQUEST_METHODS,
} from 'modules/common/types';
import { toastSuccessOrWarn } from 'modules/common/utils';
import { CONTAINER_BOX_API_URLS, DEVICES_API_URLS } from 'modules/devices/constants';
import { resetDeviceLogs, setDeviceLogsListPage } from 'modules/devices/store/deviceLogsListStore';
import {
  AddNewDeviceBody,
  AddNewDeviceResponse,
  DeviceItem,
  DeviceLogInfo,
  DeviceLogInfoList,
  DevicesList,
  DevicesListQueryParams,
  SCANNING_DEVICE_STATUS,
  UpdateDeviceAccessCodeBody,
  UpdateDeviceBody,
  UpdateDeviceResponse,
} from 'modules/devices/types';

export const devicesApi = quickDropAdminApi
  .enhanceEndpoints({
    addTagTypes: [
      'Devices',
      'Certificates',
      'Location',
      'DeviceLogInfo',
      'ContainerBoxes',
      'WaitingListLength',
    ],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getDevices: builder.query<DevicesList, DevicesListQueryParams>({
        query: ({
          page,
          sort,
          order,
          status,
          operatorUId,
          take,
          deviceIdSearch,
          type,
          isOnContainerBox,
        }) => {
          const params: { [k: string]: string | number | undefined | null } = {
            'pagination[page]': page,
            'pagination[take]': take,
            [`sort[${sort}]`]: order,
            'filters[status][$in][]': status,
            'filters[organizationUId][$in][]': operatorUId,
            'filters[deviceId][$regex]': deviceIdSearch || undefined,
            'filters[deviceId][$options]': deviceIdSearch
              ? REGEX_OPTIONS.CASE_INSENSITIVE
              : undefined,
            'filters[type][$in][]': type,
          };

          if (isOnContainerBox !== undefined) {
            if (isOnContainerBox) {
              params['filters[containerBoxUId][$ne]'] = '';
            } else {
              params['filters[containerBoxUId][$eq]'] = '';
            }
          }

          return {
            url: DEVICES_API_URLS.DEVICES_LIST,
            params,
            method: REQUEST_METHODS.GET,
          };
        },
        providesTags: (result) => (!result ? [] : ['Devices']),
      }),
      getDevice: builder.query<DeviceItem, string>({
        query: (id: string) => ({
          url: DEVICES_API_URLS.DEVICE_BY_ID(id),
          method: REQUEST_METHODS.GET,
        }),
        providesTags: (result) => (!result ? [] : ['Devices']),
      }),
      updateDevice: builder.mutation<UpdateDeviceResponse, UpdateDeviceBody>({
        query: (body) => {
          const { id, locationForRTK, ...rest } = body;
          return {
            body: rest,
            url: DEVICES_API_URLS.DEVICE_BY_ID(id),
            method: REQUEST_METHODS.PATCH,
          };
        },
        extraOptions: { disableDefaultSuccessToaster: true },
        invalidatesTags: (_result, error, args) => {
          const tagsToInvalidate: (
            | 'Devices'
            | 'Certificates'
            | 'Location'
            | 'DeviceLogInfo'
            | 'ContainerBoxes'
          )[] = [];
          if (!error) {
            tagsToInvalidate.push('Devices', 'Certificates', 'DeviceLogInfo');
            if (args.locationUId) {
              tagsToInvalidate.push('Location');
            }
            if (args.containerBoxUId) {
              tagsToInvalidate.push('ContainerBoxes');
            }
          }
          return tagsToInvalidate;
        },
        async onQueryStarted({ id, ...update }, { dispatch, queryFulfilled }) {
          const isLocationToBeUpdated = 'locationUId' in update;
          const isCertificateToBeUpdated = 'certificateUId' in update;
          const isStatusToBeUpdated = 'status' in update;
          let locationUIdBeforUpdate;
          let certificateUIdBeforUpdate;
          let statusBeforUpdate;

          const updatedResult = dispatch(
            devicesApi.util.updateQueryData('getDevice', id, (results) => {
              locationUIdBeforUpdate = results.locationUId;
              certificateUIdBeforUpdate = results.certificateUId;
              statusBeforUpdate = results.status;
              const { locationUId, locationForRTK, ...rest } = update;
              if (locationUId && locationForRTK) {
                return {
                  ...results,
                  ...rest,
                  locationUId,
                  locationAddress: locationForRTK.address,
                  locationCity: locationForRTK.city,
                  locationZip: locationForRTK.zipCode,
                };
              }
              return { ...results, ...rest };
            }),
          );

          try {
            const updatedDevice = await queryFulfilled;
            dispatch(setDeviceLogsListPage(1));
            dispatch(resetDeviceLogs());

            const isLocationUIdEmpty =
              update.locationUId === '' ||
              (!isLocationToBeUpdated && locationUIdBeforUpdate === '');
            const isCertificationUIdEmpty =
              update.certificateUId === '' ||
              (!isCertificateToBeUpdated && certificateUIdBeforUpdate === null);
            const desiredToBeActive =
              update.status === SCANNING_DEVICE_STATUS.ACTIVE ||
              (!isStatusToBeUpdated && statusBeforUpdate === SCANNING_DEVICE_STATUS.ACTIVE);

            const backendChangedStatusToSuspended =
              desiredToBeActive && updatedDevice.data.status === SCANNING_DEVICE_STATUS.SUSPENDED;

            toastSuccessOrWarn([
              {
                isWarning: isLocationUIdEmpty && backendChangedStatusToSuspended,
                warningMsg: i18n.t('devices.noLocationWarningMsg'),
              },
              {
                isWarning: isCertificationUIdEmpty && backendChangedStatusToSuspended,
                warningMsg: i18n.t('devices.noCertificationWarningMsg'),
              },
            ]);
          } catch {
            updatedResult.undo();
          }
        },
      }),
      updateAccessCode: builder.mutation<UpdateDeviceResponse, UpdateDeviceAccessCodeBody>({
        query: (body) => {
          const { id, accessCode } = body;
          return {
            body: { accessCode },
            url: DEVICES_API_URLS.UPDATE_DEVICE_ACCESS_CODE_BY_ID(id),
            method: REQUEST_METHODS.PATCH,
          };
        },
        async onQueryStarted(_args, { dispatch, queryFulfilled }) {
          await queryFulfilled;
          dispatch(setDeviceLogsListPage(1));
          dispatch(resetDeviceLogs());
        },
        invalidatesTags: (_result, error) => (error ? [] : ['DeviceLogInfo']),
      }),
      addNewDevice: builder.mutation<AddNewDeviceResponse, AddNewDeviceBody>({
        query: ({ noteData, ...body }) => ({
          url: DEVICES_API_URLS.DEVICES_ADD,
          body: { ...body, noteData: { text: noteData } },
          method: REQUEST_METHODS.POST,
        }),
        invalidatesTags: (_result, error) => (error ? [] : ['Devices', 'Certificates', 'Location']),
        async onQueryStarted(args, { queryFulfilled }) {
          try {
            const createdDevice = await queryFulfilled;

            const isLocationUIdEmpty = args.locationUId === '';
            const desiredToBeActive = args.status === SCANNING_DEVICE_STATUS.ACTIVE;
            const backendChangedStatusToSuspended =
              desiredToBeActive && createdDevice.data.status === SCANNING_DEVICE_STATUS.SUSPENDED;

            toastSuccessOrWarn({
              isWarning: isLocationUIdEmpty && backendChangedStatusToSuspended,
              warningMsg: i18n.t('devices.noLocationWarningMsg'),
            });
          } catch {
            // error handled in config api
          }
        },
        extraOptions: { disableDefaultSuccessToaster: true },
      }),
      getDeviceLogInfo: builder.query<DeviceLogInfoList, LogInfoQueryParams>({
        query: ({ page, id }) => {
          const params = {
            'pagination[page]': page,
            'filters[deviceId]': id,
          };
          return {
            url: DEVICES_API_URLS.DEVICE_LOG_INFO,
            params,
            method: REQUEST_METHODS.GET,
          };
        },
        providesTags: (_result, error) => (error ? [] : ['DeviceLogInfo']),
      }),
      addDeviceComment: builder.mutation<DeviceLogInfo, CommentPostRequest>({
        query: ({ id, comment }) => ({
          url: DEVICES_API_URLS.DEVICE_LOG_INFO,
          body: { deviceId: id, comment },
          method: REQUEST_METHODS.POST,
        }),
        invalidatesTags: (_result, error) => (error ? [] : ['DeviceLogInfo']),
      }),
      getContainerWaitingListLength: builder.query<number, string>({
        query: (containerBoxUId: string) => ({
          url: CONTAINER_BOX_API_URLS.WAITING_LIST_LENGTH(containerBoxUId),
          method: REQUEST_METHODS.GET,
        }),
        providesTags: (result) => (!result ? [] : ['WaitingListLength']),
      }),
    }),
  });

export const {
  useGetDevicesQuery,
  useGetDeviceQuery,
  useUpdateDeviceMutation,
  useUpdateAccessCodeMutation,
  useAddNewDeviceMutation,
  useLazyGetDeviceLogInfoQuery,
  useAddDeviceCommentMutation,
  useGetContainerWaitingListLengthQuery,
} = devicesApi;
